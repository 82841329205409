export const animateUnderlineNotActive = {
  '&:after': {
    transform: 'scaleX(0)',
    content: '""',
    transition: 'transform 250ms ease-in-out',
    display: 'block',
    borderBottom: '2px solid',
    transformOrigin: '0% 0%',
  },
};

export const animateUnderlineActive = {
  '&:after': {
    transform: 'scaleX(1)',
    color: '$interactiveBorderActive',
    transformOrigin: '0% 10%',
  },
};
