import Heading from 'DesignSystem/Typography/Headings/Heading';
import TextBlockModel from 'Models/Blocks/TextBlock/TextBlockModel.interface';
import XHtmlArea from 'DesignComponents/Molecules/XHtmlArea/XHtmlArea';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import { styled } from 'Theme/stitches.config';

type PropType = {
  content: TextBlockModel;
};

function TextBlock({ content: { heading, bodyText, inEditMode } }: PropType) {
  return (
    <Root>
      <Heading
        {...applyEditModeAttr(inEditMode && 'Heading')}
        tag="h2"
        size="l"
        weights="semiBold"
        css={{
          mb: 4,
          fontSize: '$fontSize200',
          lineHeight: '$lh24',
          marginBottom: '8px',
          '@bpMin721': {
            fontSize: '$fontSize500',
            lineHeight: '$lh32',
          },
        }}
      >
        {heading}
      </Heading>
      <XHtmlArea
        css={{ color: '$onSurface' }}
        {...applyEditModeAttr(inEditMode && 'BodyText')}
        content={bodyText}
      />
    </Root>
  );
}

const Root = styled('section', {
  mx: 'auto',
  my: 0,
  px: 0,
  py: '$s200',
  wh: '100%',
  maxW: 640,
  '& p': {
    lineHeight: '$lh24',
    fontSize: '$fontSize100',
    fontFamily: 'fontRegular',
  },
  '@bpMin721': {
    py: '$s300',
  },
});

export default TextBlock;
