import React, { useEffect, useRef } from 'react';
import { isInternalLink } from 'Shared/Common/Helpers';
import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';

import { BaseKexLinkStyle } from 'Kex/BaseKexLinkStyle';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';

type PropType = {
  content: string;
  smallText?: true;
  css?: CSS;
};

const HtmlArea = ({ content, smallText, ...rest }: PropType) => {
  const elmRef = useRef<HTMLDivElement>(null);
  const kexNavigate = useKexNavigate();
  useEffect(() => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      const url = e.currentTarget.href;
      if (e.currentTarget.target !== '_blank' && isInternalLink({ url })) {
        e.preventDefault();
        kexNavigate(url);
      }
    };

    const links: HTMLAnchorElement[] = elmRef.current
      ? [].slice.call(elmRef.current.querySelectorAll('a'))
      : [];

    links.forEach((link) => link.addEventListener('click', () => onClick));
    return () =>
      links.forEach((link) => link.removeEventListener('click', () => onClick));
  }, [kexNavigate]);

  const StyledHtmlArea = getStyledHtmlArea(smallText);

  return (
    <StyledHtmlArea
      {...rest}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

const SmallText = {
  ...BaseKexLinkStyle,
  fs: 6,
  textDecoration: 'underline solid',
  mb: 0,
};

const BigText = {
  ...BaseKexLinkStyle,
  fs: 8,
  textDecoration: 'underline solid',
  mb: 0,
};

const BaseParagraphStyle = {
  color: '$textPrimary',
  variants: {
    size: {
      xs: {
        fs: 6,
        lineHeight: '$lh133',
      },
      s: {
        fs: 8,
        lineHeight: '$lh15',
      },
      l: {
        fs: 9,
        lineHeight: '$lh155',
      },
    },
  },
  defaultVariants: {
    size: 's',
  },
};

const extraTopSpace = {
  pt: 4,
};

const getStyledHtmlArea = (smallText?: true) =>
  styled('div', {
    '& p': smallText
      ? { ...BaseParagraphStyle, fs: 6, mb: 4 }
      : { ...BaseParagraphStyle, mb: 4 },
    '& a': smallText ? SmallText : BigText,
    '& ul': {
      listStyle: 'disc',
      m: '0 0 $s100',
      pl: 8,
    },
    '& ol': {
      listStyle: 'decimal',
      m: '0 0 $s100',
      pl: 8,
    },
    '& li': {
      fontSize: '$fontSize100',
      mb: 2.5,
    },
    'h1, h2, h3, h4, h5, h6': {
      m: '0 0 $s100',
      fontFamily: 'fontBold',
    },
    'p + :is(h1, h2, h3, h4, h5, h6), p + p:has(> strong:first-child:last-child)':
      {
        ...extraTopSpace,
      },
    'ul + :is(h1, h2, h3, h4, h5, h6), ol + :is(h1, h2, h3, h4, h5, h6)': {
      ...extraTopSpace,
    },
    '> *': {
      display: 'block',
    },
  });

export default HtmlArea;
