import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';
import { styled } from 'Theme/stitches.config';
import { timings } from 'DesignSystem/Animations/animation';

export const BaseKexLinkStyle = {
  display: 'inline-block',
  cursor: 'pointer',
  fs: 8,
  Letter: 1,
  lineHeight: '$lh15',
  textDecoration: 'underline solid transparent',
  transitionDuration: timings.oneFifth,
  transitionProperty: 'all',
  textUnderlineOffset: '4px',
  transitionTimingFunction: 'ease-in-out',
  '@bpMin961': {
    mb: 2,
  },
  color: '$linkText',
  variants: {
    fontFamily: {
      primary400: {
        fontFamily: 'fontRegular',
        wordSpacing: '$wordSpacings$fontPrimary',
        textTransform: 'uppercase',
      },
      primary500: {
        fontFamily: 'fontMedium',
        wordSpacing: '$wordSpacings$fontPrimary',
        textTransform: 'uppercase',
      },
      secondary400: {
        fontFamily: 'fontRegular',
      },
      secondary500: {
        fontFamily: 'fontMedium',
      },
    },
    underlined: {
      true: {
        ...animateUnderlineNotActive,
        '&:hover': {
          ...animateUnderlineActive,
        },
      },
    },
  },
  defaultVariants: {
    fontFamily: 'secondary500',
  },
};

export const KexLinkBase = styled('a', {
  ...BaseKexLinkStyle,
});
